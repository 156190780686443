import React, { Fragment, useCallback, useState } from 'react';

import type { LocationOrAllMarketsType } from '@zola-helpers/client/dist/es/marketplace';
import {
  BANDS_DJS_TAXONOMY_KEY,
  VendorTaxonomyKey,
  VENUES_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import insertInArrayIf from '@zola-helpers/client/dist/es/util/insertInArrayIf';
import {
  StorefrontCardView,
  StorefrontSocialProofView,
  VendorDetailCardView,
} from '@zola/svc-marketplace-ts-types';
import { CreateIconComponentProps } from '@zola/zola-ui/src/components/SvgIcons/CreateIconComponent';
import { CalendarEventIcon } from '@zola/zola-ui/src/components/SvgIconsV3/CalendarEvent';
import { CheckIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Check';
import { CircleDollarIcon } from '@zola/zola-ui/src/components/SvgIconsV3/CircleDollar';
import { ClipboardCheckIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ClipboardCheck';
import { ConfettiIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Confetti';
import { HomeIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Home';
import { MapPinIcon } from '@zola/zola-ui/src/components/SvgIconsV3/MapPin';
import { PhotoPolaroidsIcon } from '@zola/zola-ui/src/components/SvgIconsV3/PhotoPolaroids';
import { SunIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Sun';
import { TagIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Tag';
import { UserIcon } from '@zola/zola-ui/src/components/SvgIconsV3/User';
import { UsersIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Users';
import { VideoIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Video';
import { useEffectOnce } from '@zola/zola-ui/src/hooks';
import COLORS3, { COLORS_SEMANTIC } from '@zola/zola-ui/src/styles/emotion/colors3';
import getVendorIconV3 from '@zola/zola-ui/src/util/getVendorIconV3';

import {
  getDisplayPrice,
  getPerPriceText,
  getVendorsPerPrice,
  PricePerTextProps,
  showPerEntityPricing,
} from '~/components/common/cards/util/helper';
import { SocialProof } from '~/components/common/modals/InquiryConfirmationModal/components/SocialProof/SocialProof';
import {
  PlannerServiceLevelChildKey,
  MusicianTypeChildKey,
  PhotographyStyleChildKey,
  VideographyStyleChildKey,
} from '~/types/facets';
import featureFlags from '~/util/featureFlags';
import {
  formatVenueCapacity,
  formatVenueServiceLevels,
  formatVenueSettings,
  getVenueSettingsTexts,
} from '~/util/vendorUtils';

import { VendorCardVendor } from '../../util/types';
import { getCardVendorType, getMusiciansType } from './VendorTypeAndLocation';

import styles from './cardDetails.module.less';

/** Properties needed to generate the meta-data (list of <label+icon>) for the details to show on a card */
type CardMetaProps = {
  homeText?: (queriedLocation: string) => string | null;
  perPriceCopy?: PricePerTextProps;
  details: VendorDetailCardView | null;
  displayLocation: boolean;
  displayPrice: string | null;
  metroType?: StorefrontCardView.MetroTypeEnum | null;
  queriedLocation?: string;
  showDetails?: boolean;
  vendorTypeKey?: VendorTaxonomyKey | null;
};

type CardDetailMeta = {
  icon: React.FunctionComponent<CreateIconComponentProps>;
  label: string;
  longLabel?: string;
  isCategory?: boolean;
  dataTestId?: string;
  color?: string;
};

type CardDetailsProps = {
  hasNoMinumumPrice?: boolean;
  priceTier: number | null;
  showHomeService?: boolean;
  socialProof: StorefrontSocialProofView | null;
  vendorTypeKey?: VendorTaxonomyKey | null;
} & CardMetaProps;

const getBeauticianCardDetailMeta = (
  details?: VendorDetailCardView | null
): CardDetailMeta | null => {
  // @ts-expect-error FIXME: to be removed when types are updated
  const beautyTeamSize = details?.stylistCount;

  if (beautyTeamSize) {
    return {
      label: `Team of ${beautyTeamSize}`,
      icon: beautyTeamSize === 1 ? UserIcon : UsersIcon,
    };
  }
  return null;
};

export const BeauticianCardDetails = ({
  details,
  ...rest
}: { details: VendorDetailCardView | null } & React.HTMLAttributes<HTMLDivElement>) => {
  const meta = getBeauticianCardDetailMeta(details);
  if (meta) {
    return <div {...rest}>{meta.label}</div>;
  }
  return null;
};

export const getVendorCardPriceMetaFields = ({ vendor }: { vendor: VendorCardVendor }) => {
  const { price, taxonomyNodeKey, priceRangeVisible, details } = vendor;
  const hasNoMinumumPrice = price?.min === null || price?.min === undefined;
  const whichDisplayPrice = showPerEntityPricing(taxonomyNodeKey, price)
    ? getVendorsPerPrice(taxonomyNodeKey, details)
    : getDisplayPrice(price?.min);

  const displayPrice = priceRangeVisible ? whichDisplayPrice : null;

  const perPriceCopy = hasNoMinumumPrice && taxonomyNodeKey ? getPerPriceText(taxonomyNodeKey) : '';

  return { displayPrice, perPriceCopy };
};

export const getPricingCardDetailMeta = ({
  perPriceCopy,
  displayPrice,
}: {
  perPriceCopy?: PricePerTextProps;
  displayPrice: string | null;
}): CardDetailMeta | null => {
  if (displayPrice) {
    return {
      icon: CircleDollarIcon,
      label: `Starts at ${displayPrice} ${perPriceCopy}`,
      dataTestId: 'pricing',
    };
  }
  return null;
};

const PricingCardDetails = ({
  displayPrice,
  perPriceCopy,
  ...rest
}: {
  perPriceCopy: PricePerTextProps | undefined;
} & Pick<CardDetailsProps, 'displayPrice'> &
  React.HTMLAttributes<HTMLDivElement>) => {
  const meta = getPricingCardDetailMeta({ displayPrice, perPriceCopy });
  if (meta) {
    return (
      <div className={styles.pricing} data-testid={meta.dataTestId} {...rest}>
        {meta.label}
      </div>
    );
  }
  return null;
};

const getVenueCapacityCardDetailMeta = (
  details: VendorDetailCardView | null
): CardDetailMeta | null => {
  // @ts-expect-error FIXME: to be removed when types are updated
  const formattedMaxCapacity = formatVenueCapacity(details?.capacity?.max);

  if (formattedMaxCapacity) {
    return {
      label: formattedMaxCapacity,
      icon: UsersIcon,
    };
  }
  return null;
};

export const VenueCapacityCardDetails = ({
  details,
  ...rest
}: { details: VendorDetailCardView | null } & React.HTMLAttributes<HTMLDivElement>) => {
  const meta = getVenueCapacityCardDetailMeta(details);
  if (meta) {
    return <div {...rest}>{meta.label}</div>;
  }

  return null;
};

const getVenueServiceLevelCardDetailMeta = (
  details: VendorDetailCardView | null
): CardDetailMeta | null => {
  // @ts-expect-error FIXME: to be removed when types are updated
  const formattedServiceLevels = formatVenueServiceLevels(details?.serviceLevel);
  if (formattedServiceLevels) {
    return {
      label: formattedServiceLevels,
      icon: CheckIcon,
    };
  }
  return null;
};

export const VenueServiceLevelCardDetails = ({
  details,
  ...rest
}: { details: VendorDetailCardView | null } & React.HTMLAttributes<HTMLDivElement>) => {
  const meta = getVenueServiceLevelCardDetailMeta(details);
  if (meta) {
    return <div {...rest}>{meta.label}</div>;
  }
  return null;
};

const VENUE_SETTING_ICON_MAP: Record<
  'indoor & outdoor' | 'indoor' | 'outdoor',
  React.FunctionComponent<CreateIconComponentProps>
> = {
  indoor: HomeIcon,
  outdoor: SunIcon,
  'indoor & outdoor': ConfettiIcon,
};

const getVenueSettingCardDetailMeta = (
  details: VendorDetailCardView | null
): CardDetailMeta | null => {
  // @ts-expect-error FIXME: to be removed when types are updated
  const formattedVenueSettings = formatVenueSettings(details?.venueSetting);
  // @ts-expect-error FIXME: to be removed when types are updated
  const venueSettingType = getVenueSettingsTexts(details?.venueSetting || []).join(' & ');

  const icon =
    venueSettingType in VENUE_SETTING_ICON_MAP
      ? VENUE_SETTING_ICON_MAP[venueSettingType as keyof typeof VENUE_SETTING_ICON_MAP]
      : undefined;

  if (formattedVenueSettings && icon) {
    return {
      label: formattedVenueSettings,
      icon: icon,
    };
  }
  return null;
};

export const VenueSettingCardDetails = ({
  details,
  ...rest
}: { details: VendorDetailCardView | null } & React.HTMLAttributes<HTMLDivElement>) => {
  const meta = getVenueSettingCardDetailMeta(details);
  if (meta) {
    return <div {...rest}>{meta.label}</div>;
  }
  return null;
};

const getServiceLocationDetailMeta = ({
  awayServiceText = (l: string) => `Serves ${l} for additional fees`,
  queriedLocation,
  selectedLocation,
  vendor,
  homeServiceText = (l: string) => `Serves ${l}`,
  homeText = () => null,
  metroType,
}: {
  awayServiceText?: (queriedLocation: string) => string;
  homeServiceText?: (queriedLocation: string) => string;
  homeText?: (queriedLocation: string) => string | null;
  queriedLocation?: CardDetailsProps['queriedLocation'];
  selectedLocation?: LocationOrAllMarketsType | null;
  vendor?: VendorCardVendor;
  metroType?: CardDetailsProps['metroType'];
}): CardDetailMeta | null => {
  const showServesLocation = // override on preauth for non-venues
    vendor?.taxonomyNodeKey !== 'wedding-venues' &&
    selectedLocation?.storefrontSearchPayload?.city !== vendor?.city &&
    queriedLocation !== 'All locations';
  const vendorMarketTextMap: Record<StorefrontCardView.MetroTypeEnum, () => string | null> = {
    HOME_SERVICE: () => (queriedLocation ? homeServiceText(queriedLocation) : null),
    AWAY: () => (queriedLocation ? awayServiceText(queriedLocation) : null),
    HOME: () => (queriedLocation && homeText(queriedLocation) ? homeText(queriedLocation) : null),
    // including these because we will get TS errors otherwise; we don't really use them
    RANDOMIZE_SCORES: () => null,
    IN_THIS_CITY: () => null,
    OTHER: () => null,
  };

  const result =
    (metroType && vendorMarketTextMap[metroType] && vendorMarketTextMap[metroType]()) ||
    (showServesLocation && vendorMarketTextMap.HOME_SERVICE());
  if (result) {
    return {
      label: result,
      icon: MapPinIcon,
    };
  }
  return null;
};

export const ServiceLocationDetails = ({
  awayServiceText = (l: string) => `Serves ${l} for additional fees`,
  queriedLocation,
  homeServiceText = (l: string) => `Serves ${l}`,
  homeText = () => null,
  metroType,
  ...rest
}: {
  awayServiceText?: (queriedLocation: string) => string;
  homeServiceText?: (queriedLocation: string) => string;
  homeText?: (queriedLocation: string) => string | null;
} & Pick<CardDetailsProps, 'queriedLocation' | 'metroType'> &
  React.HTMLAttributes<HTMLDivElement>): JSX.Element | null => {
  const meta = getServiceLocationDetailMeta({
    awayServiceText,
    queriedLocation,
    homeServiceText,
    homeText,
    metroType,
  });
  if (meta) {
    return <div {...rest}>{meta.label}</div>;
  }
  return null;
};

const getPlannerServiceLevelCardDetailMeta = (
  details: VendorDetailCardView | null
): CardDetailMeta | null => {
  // @ts-expect-error FIXME: to be removed when types are updated
  const serviceLevels = details?.plannerServiceLevels as PlannerServiceLevelChildKey[] | undefined;

  if (serviceLevels && serviceLevels.length > 0) {
    let text;
    if (serviceLevels.length === 1) {
      text = 'Offers 1 service';
    } else {
      text = `Offers ${serviceLevels.length} services`;
    }
    return {
      label: text,
      icon: ClipboardCheckIcon,
    };
  }
  return null;
};

export const PlannerServiceLevels = ({
  details,
  ...rest
}: { details: VendorDetailCardView | null } & React.HTMLAttributes<HTMLDivElement>) => {
  const meta = getPlannerServiceLevelCardDetailMeta(details);
  if (meta) {
    return <div {...rest}>{meta.label}</div>;
  }
  return null;
};

const getVendorCategoryMeta = (vendor: VendorCardVendor) => {
  if (vendor.taxonomyNodeKey) {
    // @ts-expect-error FIXME: to be removed when types are updated
    const musicianType = vendor.details?.musicianType as MusicianTypeChildKey[] | undefined;

    return {
      label: getCardVendorType(vendor.taxonomyNodeKey, musicianType),
      icon: getVendorIconV3(vendor.taxonomyNodeKey),
      isCategory: true,
    };
  }
  return null;
};

const getMusicianDetailsMeta = (details: VendorDetailCardView | null): CardDetailMeta | null => {
  // @ts-expect-error FIXME: to be removed when types are updated
  const musicianType = details?.musicianType as MusicianTypeChildKey[] | undefined;

  if (
    details &&
    // @ts-expect-error: details types are not correct
    details['@class'] ===
      'com.zola.service.marketplace.api.search.DetailCardViews$MusicianDetailView'
  ) {
    return {
      label: getMusiciansType(musicianType),
      icon: getVendorIconV3(BANDS_DJS_TAXONOMY_KEY),
      isCategory: true,
    };
  }
  return null;
};

export const MusicianDetails = ({
  details,
  ...rest
}: { details: VendorDetailCardView | null } & React.HTMLAttributes<HTMLDivElement>) => {
  const meta = getMusicianDetailsMeta(details);
  if (meta) {
    return <div {...rest}>{meta.label}</div>;
  }
};

const PHOTOGRAPHY_STYLE_LABELS: Record<
  PhotographyStyleChildKey,
  { label: string; longLabel: string }
> = {
  'photography-style-classic': { label: 'Classic', longLabel: 'Classic style' },
  'photography-style-editorial': { label: 'Editorial', longLabel: 'Editorial style' },
  'photography-style-fine-art': { label: 'Fine Art', longLabel: 'Fine art style' },
  'photography-style-photojournalistic': {
    label: 'Photojournalistic',
    longLabel: 'Photojournalistic style',
  },
};

const getPhotographyStyleCardDetailMeta = (
  details: VendorDetailCardView | null
): CardDetailMeta | null => {
  // @ts-expect-error FIXME: to be removed when types are updated
  const photographyStyle = details?.photographyStyle as PhotographyStyleChildKey[] | undefined;

  if (photographyStyle && photographyStyle.length === 1) {
    return {
      ...PHOTOGRAPHY_STYLE_LABELS[photographyStyle[0]],
      icon: PhotoPolaroidsIcon,
    };
  }
  return null;
};

export const PhotographerDetails = ({
  details,
  ...rest
}: { details: VendorDetailCardView | null } & React.HTMLAttributes<HTMLDivElement>) => {
  const meta = getPhotographyStyleCardDetailMeta(details);
  if (meta) {
    return <div {...rest}>{meta.label}</div>;
  }
  return null;
};

const VIDEOGRAPHY_STYLE_LABELS: Record<
  VideographyStyleChildKey,
  { label: string; longLabel: string }
> = {
  'videography-style-cinematic': { label: 'Cinematic', longLabel: 'Cinematic style' },
  'videography-style-classic': { label: 'Classic', longLabel: 'Classic style' },
  'videography-style-documentary': { label: 'Documentary', longLabel: 'Documentary style' },
  'videography-style-storytelling': { label: 'Storytelling', longLabel: 'Storytelling style' },
  'videography-style-vintage': { label: 'Vintage', longLabel: 'Vintage style' },
};

const getVideographyStyleCardDetailMeta = (
  details: VendorDetailCardView | null
): CardDetailMeta | null => {
  // @ts-expect-error FIXME: to be removed when types are updated
  const videographyStyle = details?.videographyStyle as VideographyStyleChildKey[] | undefined;

  if (videographyStyle && videographyStyle.length === 1) {
    return {
      ...VIDEOGRAPHY_STYLE_LABELS[videographyStyle[0]],
      icon: VideoIcon,
    };
  }
  return null;
};

export const VideographerDetails = ({
  details,
  ...rest
}: { details: VendorDetailCardView | null } & React.HTMLAttributes<HTMLDivElement>) => {
  const meta = getVideographyStyleCardDetailMeta(details);
  if (meta) {
    return <div {...rest}>{meta.label}</div>;
  }
  return null;
};

const getListingMessageDetailMeta = (
  type: StorefrontCardView.ListingMessageTypeEnum | null
): CardDetailMeta | null => {
  if (type === 'OFFER') {
    return {
      label: 'Offer',
      icon: TagIcon,
      color: COLORS_SEMANTIC.POSITIVE_100,
    };
  }

  if (type === 'OPEN_HOUSE') {
    return {
      label: 'Open house',
      icon: CalendarEventIcon,
      color: COLORS3.BAY_100,
    };
  }

  return null;
};

export const getCardDetailsMeta = ({
  vendor,
  queriedLocation,
  selectedLocation,
  displayPrice,
  perPriceCopy,
}: {
  vendor: VendorCardVendor;
  perPriceCopy?: PricePerTextProps;
  displayPrice: string | null;
  queriedLocation?: string;
  selectedLocation?: LocationOrAllMarketsType | null;
}): CardDetailMeta[] => {
  const { details } = vendor;

  return [
    getVendorCategoryMeta(vendor),
    getVenueCapacityCardDetailMeta(details),
    getVenueServiceLevelCardDetailMeta(details),
    getVenueSettingCardDetailMeta(details),
    getPhotographyStyleCardDetailMeta(details),
    getVideographyStyleCardDetailMeta(details),
    getPlannerServiceLevelCardDetailMeta(details),
    getBeauticianCardDetailMeta(details),
    getPricingCardDetailMeta({ displayPrice, perPriceCopy }),
    getServiceLocationDetailMeta({
      metroType: vendor.metroType,
      queriedLocation,
      selectedLocation,
      vendor,
    }),
    getListingMessageDetailMeta(vendor.listingMessageType),
  ].filter((x): x is CardDetailMeta => Boolean(x));
};

const BaseCardDetails = ({
  className,
  separator,
  homeText,
  perPriceCopy,
  details,
  displayLocation,
  displayPrice,
  metroType,
  queriedLocation,
  showDetails,
  vendorTypeKey,
}: CardMetaProps & {
  className?: string;
  separator: () => JSX.Element;
}) => {
  const style = {
    display: 'inline-block',
  };

  const isVenue = vendorTypeKey === VENUES_TAXONOMY_KEY;

  const parts = [
    ...insertInArrayIf(Boolean(showDetails), VenueCapacityCardDetails({ details, style })),
    ...insertInArrayIf(
      Boolean(isVenue && showDetails),
      VenueServiceLevelCardDetails({ details, style })
    ),
    ...insertInArrayIf(
      Boolean(isVenue && showDetails),
      VenueSettingCardDetails({ details, style })
    ),
    ...insertInArrayIf(
      displayLocation,
      ServiceLocationDetails({ homeText, metroType, queriedLocation, style })
    ),
    ...insertInArrayIf(Boolean(showDetails), PhotographerDetails({ details, style })),
    ...insertInArrayIf(Boolean(showDetails), VideographerDetails({ details, style })),
    ...insertInArrayIf(Boolean(showDetails), PlannerServiceLevels({ details, style })),
    ...insertInArrayIf(Boolean(showDetails), BeauticianCardDetails({ details, style })),
    ...insertInArrayIf(
      Boolean(displayPrice),
      PricingCardDetails({
        displayPrice,
        perPriceCopy,
        style,
      })
    ),
  ].filter(Boolean) as JSX.Element[];

  if (parts.length === 0) {
    return <Fragment />;
  }

  return (
    <div className={className}>
      {parts.map((part, index) => {
        return (
          <Fragment key={index}>
            {index !== 0 && separator()}
            {part}
          </Fragment>
        );
      })}
    </div>
  );
};

export const CardDetails = (props: CardDetailsProps) => {
  const {
    displayLocation,
    displayPrice,
    details,
    hasNoMinumumPrice,
    metroType,
    queriedLocation,
    showDetails = true,
    showHomeService = false,
    socialProof,
    vendorTypeKey,
  } = props;

  const perPriceCopy = hasNoMinumumPrice && vendorTypeKey ? getPerPriceText(vendorTypeKey) : '';

  const [enableSocialProof, setEnableSocialProof] = useState(false);
  useEffectOnce(() => {
    setEnableSocialProof(featureFlags.get('enableSocialProofOnStorefrontCard'));
  });

  const homeText = useCallback(
    (location: string) => {
      if (showHomeService) {
        return `Based in ${location}`;
      }
      return null;
    },
    [showHomeService]
  );

  return (
    <div className={styles.details}>
      {showDetails && (
        <BaseCardDetails
          className={styles.facets}
          details={details}
          displayLocation={displayLocation}
          displayPrice={displayPrice}
          homeText={homeText}
          metroType={metroType}
          perPriceCopy={perPriceCopy}
          queriedLocation={queriedLocation}
          separator={() => <span className={styles.dot}>&#8226;</span>}
          showDetails={showDetails}
          vendorTypeKey={vendorTypeKey}
        />
      )}
      {Boolean(displayPrice) && !showDetails && (
        <PricingCardDetails displayPrice={displayPrice} perPriceCopy={perPriceCopy} />
      )}
      {enableSocialProof && socialProof && <SocialProof socialProof={socialProof} />}
    </div>
  );
};
